import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

class Clients extends Component {
  state = {
    clients: [
      { id: 1, img: "assets/images/clients/koalition.png", img2x: "assets/images/clients/koalition_2x.png", link: "https://koalition.se" },
      { id: 2, img: "assets/images/clients/familify.png", img2x: "assets/images/clients/familify_2x.png", link: "https://familify.app" },
      { id: 3, img: "assets/images/clients/josefnorlin.png", img2x: "assets/images/clients/josefnorlin_2x.png", link: "https://josefnorlin.com" },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <section className="section-sm bg-light">
          <Container>
            <Row>
              {this.state.clients.map((client, key) => (
                <Col md="4" key={key}>
                  <div className="client-images my-3 my-md-0">
                    <a href={client.link} rel="noreferrer" target="_blank">
                      <img
                        src={client.img}
                        srcset={`${client.img} 1x, ${client.img2x} 2x`}
                        alt="logo-img"
                        className="mx-auto img-fluid d-block"
                        />
                    </a>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Clients;
